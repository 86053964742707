import Vue from 'vue'
import App from './App.vue'
import _ from "lodash";
import "@/assets/styles/app.scss"; // global css
import VueStorage from "vue-ls";
import router from './router'
import {
  Modal,
  Button,
  FormModel,
  Input,
  Icon,
  Carousel,
  Row,
  Col,
  Breadcrumb,
  Empty,
  ConfigProvider,
  Pagination,
  Spin,
  Form,
  Select
} from 'ant-design-vue';
import MyIcon from "@/components/MyIcon/index"
import CustomTitle from "@/components/CustomTitle"
import CustomPage from "@/components/Pagination"
import './permission';
import VuePrlx from 'vue-prlx'
Vue.use(VuePrlx);
Vue.use(Form);
Vue.use(Select);
Vue.use(Spin)
Vue.use(Modal)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Breadcrumb)
Vue.use(ConfigProvider)
Vue.use(Pagination)
Vue.component(Button.name, Button)
Vue.use(Icon)
Vue.use(Carousel)
Vue.use(Row)
Vue.use(Col)
Vue.use(Empty)
Vue.component("MyIcon", MyIcon)
Vue.component("CustomTitle", CustomTitle)
Vue.component("CustomPage", CustomPage)

Vue.config.productionTip = false
Vue.prototype._ = _;

Vue.use(VueStorage, {
  namespace: "ngj__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local" // storage name session, local, memory
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')