<template>
  <div class="page">
    <a-pagination
      v-model="page"
      :total="total"
      show-less-items
      :item-render="itemRender"
      @change="change"
    />
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      page: 1,
    };
  },
  props: {
    current: {
      type: Number,
      default: () => 1,
    },
    total: {
      type: Number,
      default: () => 1,
    },
  },
  created() {
    this.page = this.current;
  },
  components: {},
  methods: {
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return (
          <a class="ant-pagination-item-link" style="padding: 0 10px">
            上一页
          </a>
        );
      } else if (type === "next") {
        return (
          <a class="ant-pagination-item-link" style="padding: 0 10px">
            下一页
          </a>
        );
      }
      return originalElement;
    },
    change(page) {
      this.$emit("change", page);
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}
</style>
