<template>
  <custom-icon :type="type" :style="style" />
</template>

<script>
import { Icon } from "ant-design-vue";
const CustomIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2529298_wo3rk1au8k.js", // 在 iconfont.cn 上生成
});

export default {
  props: {
    styles: {
      type: Object,
      default: () => {},
    },
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    style() {
      return { verticalAlign: "middle", ...this.styles };
    },
  },
  components: {
    CustomIcon,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
