<template>
  <div class="footer">
    <div class="contract">
      <div class="container box">
        <div class="tit">代理合作</div>
        <div class="form">
          <a-form-model
            layout="inline"
            :model="form"
            @submit="handleSubmit"
            @submit.native.prevent
          >
            <a-form-model-item>
              <a-input v-model="form.username" placeholder="姓名" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.mobile" placeholder="电话" />
            </a-form-model-item>
            <a-form-model-item>
              <a-select
                v-model="form.type"
                :style="{ width: '180px' }"
                placeholder="所需服务"
              >
                <a-select-option value="1"> 农机 </a-select-option>
                <a-select-option value="2"> 农药 </a-select-option>
                <a-select-option value="3"> 托管 </a-select-option>
                <a-select-option value="4"> 入驻 </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.remark" placeholder="备注" />
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="form.username === ''"
              >
                提交
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    
    <div class="container">
      <div class="submenu">
        <div class="item" :key="menu.name" v-for="menu in _menus">
          <div class="top">
            <router-link :to="menu.path">{{ menu.meta.title }}</router-link>
          </div>
          <ul>
            <li :key="sub.name" v-for="sub in menu.children">
              <router-link :to="sub.path">{{ sub.meta.title }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="info">
        <p>
          <a-icon type="phone" />&nbsp;123-456789&nbsp;&nbsp;工作日: 9:00-18:00
        </p>
        <p><a-icon type="mail" />&nbsp;平台邮箱: 123@123.com</p>
      </div>
    </div>
    <div>
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34040202000170"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
        ><img src="~@/assets/images/ba.jpg" style="float:left;" />
        <p
          style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
        >
          皖公网安备 34040202000170号
        </p></a
      >
    </div>
    <div><a-icon type="beian" /><span>备案号:</span> <a :href="'https://beian.miit.gov.cn'" target="_blank">皖ICP备2021008910号-2</a></div>
  </div>
  
</template>

<script>
import { menus } from "@/router/routes.js";
export default {
  data() {
    return {
      form: {},
    };
  },
  computed: {
    _menus() {
      return this._.filter(menus, (item) => item.meta.submenu);
    },
  },
  methods: {
    handleSubmit() {},
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #d5d5d5;
  padding: 0 0 20px 0;
  text-align: center;
  .contract {
    background: #67d88b;
    height: 66px;
    line-height: 66px;
    .box {
      display: flex;
      .tit {
        flex: 10%;
        font-size: 26px;
        font-weight: bolder;
        font-style: italic;
        color: white;
        text-shadow: 3px 3px 0 #008840;
      }
      .form {
        flex: 90%;
        text-align: left;
        padding-left: 30px;
        padding-top: 14px;
      }
    }
  }
  > .container {
    display: flex;
    padding-top: 0px;
    a {
      color: #333;
    }
    .submenu {
      display: flex;
      flex: 70%;
      .item {
        flex: 1;
        .top {
          margin-bottom: 10px;
        }
        ul {
          li {
            list-style-type: none;
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
    .info {
      flex: 30%;
      text-align: left;
    }
  }
}
</style>
