<template>
  <div class="custom-title">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
.custom-title {
  line-height: 2em;
  border-bottom: 1px solid #dedede;
  overflow: hidden;
  height: 2.4em;
  background: url("~@/assets/images/linebg.png") no-repeat bottom center;
  text-align: center;
  font-size: 26px;
  color: #333333;
  .more {
    float: right;
    color: #5f5750;
  }
}
</style>
