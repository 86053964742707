<template>
  <div class="crumbs">
    <div class="container">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <a-icon type="home" />&nbsp;<router-link to="/">首页</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item
          :key="menu.path"
          v-for="(menu, index) in $route.matched.slice(1)"
        >
          <router-link :to="index + 2 >= $route.matched.length ? '' : menu.path">
            {{ menu.meta.title }}
          </router-link>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
    }
  },
  components: {

  },
  watch: {

  },
}
</script>

<style scoped lang="scss">
.crumbs {
  padding: 15px 0;
}
</style>
