import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 路由发生变化修改页面 title 
router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.meta.title) {
        document.title = to.meta.title + " - 淮南市农业生产统一经营平台"
    }
    next()
})

router.afterEach(() => {
    NProgress.done()
})