<template>
  <section class="app-main">
    <app-header></app-header>
    <crumbs v-if="showCrumbs" />
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <div
          :class="$route.meta.width == 'all' ? 'container-full' : 'container'"
        >
          <router-view :key="key" />
        </div>
      </keep-alive>
    </transition>
    <app-footer></app-footer>
  </section>
</template>

<script>
import AppHeader from "./Header";
import AppFooter from "./Footer";
import Crumbs from "./Crumbs";
export default {
  name: "AppMain",
  components: { AppHeader, AppFooter, Crumbs },
  data() {
    return {
      showCrumbs: false,
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  watch: {
    key() {
      if (this.key !== "" && this.key !== "/") {
        this.showCrumbs = true;
      } else {
        this.showCrumbs = false;
      }
    },
  },
  created() {
    if (this.key !== "" && this.key !== "/") {
      this.showCrumbs = true;
    } else {
      this.showCrumbs = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
