<template>
  <div class="route-view">
    <router-view :key="key" />
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {

    }
  },
  components: {

  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
}
</script>

<style scoped lang="scss"></style>
