import Layout from "@/layouts";
import RouteView from "@/layouts/RouteView";
export const menus = [{
        path: "/",
        component: () => import("@/views/index/index"),
        name: "index",
        meta: {
            title: "首页",
            menu: true,
            width: "all"
        },
    },
    {
        path: "/organizations",
        component: () => import("@/views/organizations/index"),
        redirect: "/organizations/village",
        meta: {
            title: "组织名录",
            menu: true,
        },
        children: [{
                path: "village",
                component: () => import("@/views/organizations/village"),
                name: "organization_village",
                meta: {
                    title: "村集体组织名录",
                    menu: true,
                },
            }, {
                path: "chems",
                component: () => import("@/views/organizations/chems"),
                name: "organization_chems",
                meta: {
                    title: "社会化服务组织名录",
                    menu: true,
                },
            }, {
                path: "/organizations/village/detail/:id",
                component: () => import("@/views/organizations/village_detail"),
                name: "village_organization_detail",
                meta: {
                    title: "村集体组织名录"
                },
            },
            {
                path: "/organizations/detail/:id",
                component: () => import("@/views/organizations/chems_detail"),
                name: "organization_detail",
                meta: {
                    title: "社会化服务组织名录详情"
                },
            },
        ],
    },
    {
        path: "/trusteeships",
        component: RouteView,
        meta: {
            title: "托管实例",
            menu: true,
        },
        children: [{
                path: "",
                component: () => import("@/views/trusteeships/index"),
                name: "trusteeships",
                meta: {
                    title: "托管实例",
                },
            },
            {
                path: "detail/:id",
                component: () => import("@/views/trusteeships/detail"),
                name: "trusteeship_detail",
                meta: {
                    title: "托管实例详情",
                },
            },
        ],
    },
    {
        path: "/farm_service",
        component: () => import("@/views/farm_service/index"),
        redirect: "/farm_service/goods",
        meta: {
            title: "农资农服",
            menu: true,
        },
        children: [{
                path: "goods",
                component: () => import("@/views/farm_service/goods"),
                name: "farm_service_index",
                meta: {
                    title: "农资",
                    menu: true
                },
            }, {
                path: "serves",
                component: () => import("@/views/farm_service/serves"),
                name: "farm_service_index",
                meta: {
                    title: "农服",
                    menu: true
                },
            },
            {
                path: "serve/detail/:id",
                component: () => import("@/views/farm_service/serves_detail"),
                name: "farm_service_serve_detail",
                meta: {
                    title: "农服详情",
                },
            }, {
                path: "goods/detail/:id",
                component: () => import("@/views/farm_service/goods_detail"),
                name: "farm_service_goods_detail",
                meta: {
                    title: "农资详情",
                },
            },
        ],
    },
    {
        path: "/finance_insurance",
        redirect: "/finance_insurance/credit",
        name: "finance_insurance",
        component: () => import("@/views/finance_insurance/index"),
        meta: {
            title: "金融保险",
            menu: true,
        },
        children: [{
                path: "credit",
                component: () => import("@/views/finance_insurance/credit"),
                name: "finance_insurance_credit",
                meta: {
                    title: "信贷",
                },
            },
            {
                path: "insurance",
                component: () => import("@/views/finance_insurance/insurance"),
                name: "finance_insurance_insurance",
                meta: {
                    title: "保险",
                },
            },
        ],
    },
    {
        path: "/news",
        component: () => import("@/views/news/layout"),
        meta: {
            title: "新闻资讯",
            menu: true,
        },
        children: [{
                path: "",
                component: () => import("@/views/news/index"),
                name: "news",
                meta: {
                    title: "新闻资讯",
                },
            },
            {
                path: "detail/:id",
                component: () => import("@/views/news/detail"),
                name: "news_detail",
                meta: {
                    title: "新闻详情",
                },
            },
        ],
    },
    {
        path: "/solution",
        component: () => import("@/views/solution/index"),
        name: "solution",
        meta: {
            title: "解决方案",
            menu: true,
            width: "all"
        },
    },
    {
        path: "/about",
        redirect: "/about/introduce",
        component: RouteView,
        name: "about",
        meta: {
            title: "关于我们",
            menu: true,
            submenu: true,
        },
        children: [{
            path: "/about/introduce",
            component: () => import("@/views/about/index"),
            name: "about_introduce",
            meta: {
                title: "平台简介",
                menu: true,
                submenu: true,
            },
        }, ],
    },
    {
        path: "/help",
        redirect: "/help/feedback",
        name: "help",
        meta: {
            title: "帮助中心",
            submenu: true,
        },
        children: [{
                path: "/help/feedback",
                component: () => import("@/views/index/index"),
                name: "help_feedback",
                meta: {
                    title: "意见反馈",
                    submenu: true,
                },
            },
            {
                path: "/help/download",
                component: () => import("@/views/index/index"),
                name: "help_download",
                meta: {
                    title: "文件下载",
                    submenu: true,
                },
            },
            {
                path: "/help/questions",
                component: () => import("@/views/index/index"),
                name: "help_questions",
                meta: {
                    title: "常见问题",
                    submenu: true,
                },
            },
        ],
    },
    {
        path: "/rules",
        redirect: "/rules/disclaimers",
        name: "rules",
        meta: {
            title: "平台规则",
            submenu: true,
        },
        children: [{
                path: "/rules/disclaimers",
                component: () => import("@/views/index/index"),
                name: "rules_disclaimers",
                meta: {
                    title: "免责声明",
                    submenu: true,
                },
            },
            {
                path: "/rules/api_standard",
                component: () => import("@/views/index/index"),
                name: "rules_api_standard",
                meta: {
                    title: "接口规范",
                    submenu: true,
                },
            },
        ],
    },
];

export default [{
        path: "",
        component: Layout,
        redirect: "/",
        children: [...menus],
    },
    {
        path: "/404",
        component: () => import("@/views/error/404"),
        hidden: true,
    },
    {
        path: "*",
        redirect: "/404",
    },
];