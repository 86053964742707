<template>
  <div class="top">
    <div class="container">
      <div class="header">
        <div class="logo">
          <a href="/"
            ><img src="@/assets/logo.png" alt="淮南市农业生产大托管"
          /></a>
          <span>淮南市农业生产大托管</span>
        </div>
        <div class="user-tool">
          <div class="login" @click="showLogin = !showLogin">
            <div class="text">
              <my-icon
                :styles="{ fontSize: '22px', color: '#565656' }"
                type="iconzhuce"
              ></my-icon>
              登录
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="container">
        <ul>
          <li
            :class="
              $route.path.indexOf(menu.path) == 0 && menu.path !== '/'
                ? 'current'
                : ''
            "
            :key="menu.name"
            v-for="menu in _menus"
          >
            <router-link :to="menu.path">
              {{ menu.meta.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <a-modal v-model="showLogin" title="欢迎登录">
      <a-form-model
        ref="loginForm"
        :rules="rules"
        :model="form"
        v-bind="layout"
      >
        <a-form-model-item has-feedback label="用户名" prop="username">
          <a-input v-model="form.username" type="text" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="密码" prop="password">
          <a-input v-model="form.password" type="password" autocomplete="off" />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="showLogin = false"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleLogin"
        >
          登录
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { menus } from "@/router/routes";
export default {
  data() {
    return {
      showLogin: false,
      loading: false,
      menus,
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      form: {
        username: "",
        password: "",
        remember: false,
      },
    };
  },
  computed: {
    _menus() {
      return this._.filter(this.menus, (item) => item.meta.menu);
    },
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 27px 0;
  overflow: hidden;
  .logo {
    overflow: hidden;
    float: left;
    a {
      float: left;
      img {
        height: 80px;
        display: block;
      }
    }
    span {
      line-height: 80px;
      float: left;
      font-weight: 500;
      font-size: 28px;
      margin-left: 30px;
    }
  }
  .user-tool {
    float: right;
    .login {
      padding: 5px 10px;
      border: 1px solid #e3e6ec;
      font-size: 16px;
      color: #008840;
      cursor: pointer;
      margin-top: 22px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: #008840;
        color: white;
        border-color: #008840;
      }
    }
  }
}
.menu {
  background: #008840;
  line-height: 50px;
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    li {
      list-style-type: none;
      flex: 1;
      &.current {
        a {
          background-color: #04a750;
        }
      }
      a {
        color: white;
        font-weight: 500;
        font-size: 18px;
        text-decoration: none;
        display: block;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #04a750;
        }
      }
    }
  }
}
</style>
